import langJp from "../assets/translation/jp.json"
import langEn from "../assets/translation/en.json"

export const languageToTranlate = lan => {
  let language = {
    jp: langJp,
    en: langEn,
  }

  return language[lan];
}
